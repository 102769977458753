import { useEffect } from 'react';

export function useTabClosed(handleTabClose: { (): Promise<void> }) {
  useEffect(() => {
    window.addEventListener('beforeunload', handleTabClose);

    return () => {
      window.removeEventListener('beforeunload', handleTabClose);
    };
  }, []);

  return;
}
