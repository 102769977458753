import React, { Fragment, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import PreLoginSplashScreen from 'components/Core/Autopay-schedule/ScheduleAndAutoPay/PreLoginSplashScreen/PreLoginSplashScreen';
import Alert from 'components/Shared/Icons/Alert';
import NewLabel from 'components/Shared/Icons/NewLabel';
import SegmentIO from 'reporting/SegmentIO';
import { colors, fontSize } from 'styles/cp.js';

export interface Props {
  isLoggedIn: boolean;
  isAutoPay: boolean;
  signIn: () => void;
}

const ScheduleAndAutopayMobileCard: React.FC<Props> = ({ isLoggedIn, isAutoPay, signIn }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const signInClicked = () => {
    signIn();
  };

  const openModal = () => {
    SegmentIO.signInSchedule();
    setIsModalOpen(true);
  };

  const action = isLoggedIn ? (
    ''
  ) : (
    <Fragment>
      <div className="action" onClick={() => openModal()}>
        {!isAutoPay ? (
          <FormattedMessage id="SET_A_DATE" defaultMessage="Set a date" />
        ) : (
          <FormattedMessage id="SET_UP_AUTOPAY" defaultMessage="Set up autopay" />
        )}
      </div>

      <style jsx>{`
        .action {
          font-family: AvenirNextforINTUIT-Demi;
          color: ${colors.blue};
          cursor: pointer;
          font-size: ${fontSize.xs};
          margin-left: 24px;
        }
      `}</style>
    </Fragment>
  );

  return (
    <Fragment>
      <div className="message-wrapper">
        <div className="message-content">
          {!isAutoPay && (
            <div className="alert" aria-label="Alert icon">
              <Alert />
            </div>
          )}
          <div className="message-text">
            {!isAutoPay ? (
              <FormattedMessage
                id="SCHEDULE_PAY_MOBILE_CARD"
                defaultMessage="Schedule it now, pay it later."
              />
            ) : (
              <FormattedMessage
                id={!isLoggedIn ? 'AUTO_PAY_MOBILE_CARD' : 'AUTO_PAY_CARD_LOGGED_IN'}
                defaultMessage={
                  !isLoggedIn ? 'Pay on time, every time.' : 'Turn on autopay to pay on time.'
                }
              />
            )}
          </div>
          {action}
        </div>
        <div className="new-alert-wrapper" aria-label="New label">
          <NewLabel />
        </div>
      </div>
      {isModalOpen && (
        <PreLoginSplashScreen
          isAutoPay={isAutoPay}
          closeCallback={() => setIsModalOpen(false)}
          loginCallback={() => signInClicked()}
        />
      )}

      <style jsx>{`
        .message-wrapper {
          width: 100%;
          margin-bottom: 16px;
          background-color: ${colors.white};
          border: solid 1px #007ec8;
          border-radius: 4px;
          position: relative;

          .alert {
            position: absolute;
          }

          .message-content {
            display: inline-block;
            align-content: center;
            padding: 15px 8px 14px;
            width: 100%;

            .message-text {
              font-size: ${fontSize.xs};
              font-family: AvenirNextforINTUIT-Demi;
              margin-left: 24px;
            }
          }
          .new-alert-wrapper {
            position: absolute;
            right: 8px;
            top: 8px;
          }
        }
      `}</style>
    </Fragment>
  );
};

export default ScheduleAndAutopayMobileCard;
