import React from 'react';

import { IconBasePropsWithColor } from './IconBase';

import { colors } from 'styles/cp.js';

const Alert: React.FC<IconBasePropsWithColor> = ({
  width = 20,
  height = 20,
  color = colors.lightBlue,
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill={color}
        d="M9.99935 1.66797C14.6017 1.66797 18.3327 5.39893 18.3327 10.0013C18.3327 14.6036 14.6017 18.3346 9.99935 18.3346C5.39697 18.3346 1.66602 14.6036 1.66602 10.0013C1.66602 5.39893 5.39697 1.66797 9.99935 1.66797ZM9.99935 9.16797C9.5391 9.16797 9.16602 9.54105 9.16602 10.0013V12.5013L9.1716 12.5985C9.21977 13.013 9.57202 13.3346 9.99935 13.3346C10.4596 13.3346 10.8327 12.9616 10.8327 12.5013V10.0013L10.8271 9.90414C10.7789 9.48964 10.4267 9.16797 9.99935 9.16797ZM9.99935 6.66797C9.5391 6.66797 9.16602 7.04106 9.16602 7.5013C9.16602 7.96154 9.5391 8.33464 9.99935 8.33464C10.4596 8.33464 10.8327 7.96154 10.8327 7.5013C10.8327 7.04106 10.4596 6.66797 9.99935 6.66797Z"
      />
    </svg>
  );
};

export default Alert;
